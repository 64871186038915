<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Add Vendor</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <form>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-autocomplete
                v-model="form_data.category_id"
                :error-messages="form_error.category_id"
                :items="categories"
                item-text="name"
                item-value="category_id"
                label="Category"
                outlined
                dense
                multiple
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.name"
                :error-messages="form_error.name"
                label="Name"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.email"
                :error-messages="form_error.email"
                label="Email"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.phone_number"
                :error-messages="form_error.phone_number"
                label="Phone number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.company_name"
                :error-messages="form_error.company_name"
                label="Company Name"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.title"
                :error-messages="form_error.title"
                label="Title"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                outlined
                rows="3"
                v-model="form_data.comapny_desc"
                label="Company description"
                hide-details=""
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-textarea
                outlined
                rows="3"
                v-model="form_data.company_address"
                label="Company Address"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-file-input
                v-model="form_data.v_image"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
                label="Avatar"
                outlined
                dense
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.facebook"
                :error-messages="form_error.facebook"
                label="Facebook Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.linkedin"
                :error-messages="form_error.linkedin"
                label="Linkedin Link"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.twitter"
                :error-messages="form_error.twitter"
                label="Twitter Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.instagram"
                :error-messages="form_error.instagram"
                label="Instagram Link"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.youtube"
                :error-messages="form_error.youtube"
                label="Youtube Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <!-- <v-select
                v-model="form_data.booth_color"
                :error-messages="form_error.booth_color"
                :items="booth_colors"
                item-text="name"
                item-value="color"
                label="Booth Color"
                outlined
                dense
                v-if="form_data.sponsor_type==4 || form_data.sponsor_type==5 || form_data.sponsor_type==6"
              ></v-select> -->
            </v-col>
          </v-row>
          <v-btn
            class="mr-4 mb-1"
            color="secondary"
            @click="addVendor"
            :loading="loading"
            :disabled="loading"
            >submit</v-btn
          >
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/vendor">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "AddVendor",
  data() {
    return {
      categories: [],
      sponsor_type: [],
      form_data: {
        social_links: [],
        sponsor_type: "6",
      },
      form_error: {},
      loading: false,
      data: {},
      booth_colors: [
        { name: "Gray", color: "grey" },
        { name: "Red", color: "red" },
        { name: "Red-Orange", color: "red_orange" },
        { name: "Orange", color: "orange" },
        { name: "Yellow", color: "yellow" },
        { name: "Yellow-Orange", color: "yellow_orange" },
        { name: "Yellow-Green", color: "yellow_green" },
        { name: "Green", color: "green" },
        { name: "Blue-Green", color: "blue_green" },
        { name: "Blue", color: "blue" },
        { name: "Blue-Violet", color: "blue_violet" },
        { name: "Violet", color: "violet" },
        { name: "Red-Violet", color: "red_violet" },
      ],
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    addVendor() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      let arrSocial = [];
      for (let skey in _self.form_data.social_links) {
        let value = _self.form_data.social_links[skey];
        arrSocial.push({ name: skey, link: value });
      }
      formData.append(`social_medias`, JSON.stringify(arrSocial));
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      if (this.form_data.v_image) {
        formData.append("profile_pic", this.form_data.v_image);
      }
      this.$axios
        .post(`admin/vendor/add`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/vendor");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getCategoryList() {
      let _self = this;
      this.$axios
        .get(`admin/category/list?pagination=false`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.categories = response_data.data;

            // selecting first category as default
            if (Array.isArray(_self.categories) && _self.categories.length) {
              _self.form_data.category_id = [_self.categories[0].category_id];
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getSponsorTypeList() {
      let _self = this;
      this.$axios
        .get(`admin/sponser_type/list?type=SPONSORSHIP`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.sponsor_type = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getSponsorTypeList();
    this.getCategoryList();
  },
};
</script>
